<template>
  <section class="mx-sm-4">
    <v-alert v-if="alert_etapas" type="error" dismissible>
      Debe configurar todas las etapas
      <template v-slot:close="{ toggle }">
        <v-btn @click="closeAlert(toggle)" color="white" text>
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </template>
    </v-alert>
    <v-row>
      <v-col v-if="showDatePicker" cols="12" md="12" class="mt-5">
        <p class="text-h6 secondary--text">Vigencia</p>
        <p>
          Durante este período las instituciones podrán realizar sus compras
          mediante el convenio marco. Las fechas deben ser posterior a la etapa
          de cierre
        </p>
      </v-col>
      <v-col v-if="showDatePicker" cols="12" md="4">
        <vc-date-picker
          v-model="fecha_inicio_convenio"
          mode="date"
          locale="es"
          :popover="config_calendar"
          :min-date="fecha_minima_convenio"
          @dayclick="habilitarEdit()"
        >
          <template v-slot="{ inputEvents }">
            <v-text-field
              class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
              label="Fecha de inicio *"
              prepend-inner-icon="mdi-calendar"
              readonly
              :value="getFechaInicioConvenio"
              v-on="inputEvents"
              outlined
              :error-messages="fechaInicioConvenioErrorMessage"
              @input="$v.fecha_inicio_convenio.$touch()"
              @blur="$v.fecha_inicio_convenio.$touch()"
            />
          </template>
        </vc-date-picker>
      </v-col>
      <v-col v-if="showDatePicker" cols="12" md="4">
        <vc-date-picker
          v-model="fecha_fin_convenio"
          mode="date"
          locale="es"
          :popover="config_calendar"
          :min-date="fecha_inicio_convenio"
          @dayclick="habilitarEdit()"
        >
          <template v-slot="{ inputEvents }">
            <v-text-field
              class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
              label="Fecha fin *"
              prepend-inner-icon="mdi-calendar"
              readonly
              :value="getFechaFinConvenio"
              v-on="inputEvents"
              outlined
              :error-messages="fechaFinConvenioErrorMessage"
              @input="$v.fecha_fin_convenio.$touch()"
              @blur="$v.fecha_fin_convenio.$touch()"
              :disabled="fecha_inicio_convenio === null"
            />
          </template>
        </vc-date-picker>
      </v-col>
      <v-col class="mt-1" v-if="editModeDate" cols="12" md="4">
        <v-btn
          :loading="loading"
          color="secondary"
          class="mb-5"
          x-large
          @click="guardarFechas()"
        >
          Guardar fechas
        </v-btn>
      </v-col>
      <v-col cols="12" :md="headers?.acciones ? '10' : '12'">
        <p class="text-h6 secondary--text">Etapas</p>
        <v-data-table :headers="headers" :items="items" hide-default-footer>
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip label :color="item.convenio_fase ? '#C1ECD9' : '#E0E5F1'" style="justify-content: center; width:100px;">
              {{ item.convenio_fase ? "Completada" : "Pendiente" }}
            </v-chip>
          </template>
          <template v-slot:[`item.fecha_inicio`]="{ item }">
            {{
              item.convenio_fase
                ? moment(item.convenio_fase.fecha_inicio).format(
                    "DD/MM/YYYY hh:mm A"
                  )
                : item.fecha_inicio
                ? moment(item.fecha_inicio).format("DD/MM/YYYY hh:mm A")
                : ""
            }}
          </template>
          <template v-slot:[`item.fecha_fin`]="{ item }">
            {{
              item.convenio_fase
                ? moment(item.convenio_fase.fecha_fin).format(
                    "DD/MM/YYYY hh:mm A"
                  )
                : item.fecha_fin
                ? moment(item.fecha_fin).format("DD/MM/YYYY hh:mm A")
                : ""
            }}
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :disabled="statusIcon(item)"
                  color="primary"
                  @click="configurarFase(item)"
                >
                  mdi-cog-outline
                </v-icon>
              </template>
              <span> Configurar </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showDialog"
      max-width="900px"
      @click:outside="(showDialog = false), clean()"
    >
      <v-card :height="id_phase === 1 ? '700px' : '600px'">
        <v-card-title>
        <v-icon @click="(showDialog = false), clean()">
          mdi-window-close 
        </v-icon>
        </v-card-title>
        <v-card-title>
          <p class="text-h6 secondary--text">{{ phase_name }}</p>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <vc-date-picker
                v-model="fecha_inicio"
                mode="dateTime"
                locale="es"
                :popover="config_calendar"
                :min-date="id_phase === 1 ? fecha_actual : fecha_minima"
                :max-date="fecha_maxima"
                :minute-increment="id_phase === 1 ? 5 : 0"
                :valid-hours="horas_disponibles_inicio"
                @dayclick="horasDisponibles(1)"
              >
                <template v-slot="{ inputEvents }">
                  <v-text-field
                    class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
                    label="Fecha y hora de inicio *"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    :disabled="id_phase !== 1"
                    :value="getFechaInicio"
                    v-on="inputEvents"
                    outlined
                    :error-messages="fechaInicioErrorMessage"
                    @input="$v.fecha_inicio.$touch()"
                    @blur="$v.fecha_inicio.$touch()"
                  />
                </template>
              </vc-date-picker>
            </v-col>
            <v-col cols="12" md="6">
              <vc-date-picker
                v-model="fecha_fin"
                mode="dateTime"
                locale="es"
                :popover="config_calendar"
                :min-date="getMinDate"
                :max-date="fecha_maxima"
                :minute-increment="5"
                :valid-hours="horas_disponibles_fin"
                @dayclick="horasDisponibles(2)"
              >
                <template v-slot="{ inputEvents }">
                  <v-text-field
                    class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
                    label="Fecha y hora de fin *"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    :value="getFechaFin"
                    v-on="inputEvents"
                    outlined
                    :disabled="fecha_inicio === ''"
                    :error-messages="fechaFinErrorMessage"
                    @input="$v.fecha_fin.$touch()"
                    @blur="$v.fecha_fin.$touch()"
                    @click="horasDisponibles(2)"
                  />
                </template>
              </vc-date-picker>
            </v-col>
          </v-row>
          <v-file-input
            class="mb-4"
            hint="Archivo PDF tamaño máximo 250MB"
            persistent-hint
            accept=".pdf"
            v-if="id_phase === 1"
            outlined
            label="Documento *"
            v-model="documento"
            :error-messages="documentoErrorMessage"
            @input="$v.documento.$touch()"
            @blur="$v.documento.$touch()"
            @change="isDocUpdate = true"
            :prepend-icon="null"
            prepend-inner-icon="mdi-paperclip"
          >
            <template v-if="editMode && documento !== null" v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="obtenerDocumentoBases"
                    color="green"
                    >mdi-file-download</v-icon
                  >
                </template>
                <span>Descargar archivo</span>
              </v-tooltip>
            </template>
          </v-file-input>
          <v-textarea
            outlined
            label="Observación *"
            color="secondary"
            :maxLength="250"
            v-model="observacion"
            :error-messages="observacionErrorMessage"
            @input="$v.observacion.$touch()"
            @blur="$v.observacion.$touch()"
          />
        </v-card-text>
        <v-card-actions class="mt-5">
          <v-btn
            :loading="loading"
            color="secondary"
            class="mb-5"
            @click="
              editMode ? actualizarConfiguracion() : guardarConfirguracion()
            "
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="!hideButton" class="d-flex flex-column mt-4">
      <action-button-component :step="4" />
    </div>
  </section>
</template>
<script>
import moment from "moment";
import { mapActions, mapMutations, mapState } from "vuex";
import { required, requiredIf, maxLength } from "vuelidate/lib/validators";
import LegacyValidations from "@/utils/legacy-validations";
import ActionButtonComponent from "../views/ConvenioMarco/components/ActionButtonComponent.vue";

const file_type_validation = (value, vm) => {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
};

function dateValidation() {
  return (
    moment(this.fecha_inicio).format("YYYY-MM-DD HH:mm") <
    moment(this.fecha_fin).format("YYYY-MM-DD HH:mm")
  );
}

function dateConvenioValidation() {
  if (this.showDatePicker) {
    return (
      moment(this.fecha_inicio_convenio).format("YYYY-MM-DD") <=
      moment(this.fecha_fin_convenio).format("YYYY-MM-DD")
    );
  } else {
    return true;
  }
}

export default {
  name: "EtapasProcesoComponent",
  props: {
    headers: {
      align: 'end',
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => [],
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ActionButtonComponent,
  },
  data: () => ({
    showDatePicker: false,
    editModeDate: false,
    dialogFechaInicio: false,
    dialogFechaFin: false,
    loading: false,
    isDocUpdate: false,
    editMode: false,
    showDialog: false,
    disableButton: false,
    menuDataRange: false,
    modalHoraInicio: false,
    modalHoraFin: false,
    fecha_actual: "",
    fecha_inicio: "",
    fecha_fin: "",
    fecha_inicio_convenio: "",
    fecha_fin_convenio: "",
    fecha_minima: "",
    fecha_minima_convenio: "",
    fecha_maxima: "",
    horas_disponibles_inicio: [],
    horas_disponibles_fin: [],
    id: "",
    id_phase: "",
    phase_name: "",
    document_name: "",
    document_path: "",
    documento: null,
    observacion: "",
    config_calendar: {
      visibility: "click",
    },
  }),
  validations: {
    fecha_inicio: {
      required: requiredIf(function () {
        return this.showDialog;
      }),
      dateValidation,
    },
    fecha_fin: {
      required: requiredIf(function () {
        return this.showDialog;
      }),
    },
    fecha_inicio_convenio: {
      required: requiredIf(function () {
        return this.showDatePicker && !this.editMode;
      }),
      dateConvenioValidation,
    },
    fecha_fin_convenio: {
      required: requiredIf(function () {
        return this.showDatePicker && !this.editMode;
      }),
    },
    documento: {
      required: requiredIf(function () {
        return this.id_phase === 1 && !this.editMode ? true : false;
      }),
      file_type_validation,
      fileMaxSize: LegacyValidations.maxFileSizeRule(),
    },
    observacion: {
      required: requiredIf(function () {
        return this.showDialog;
      }),
    },
  },
  watch:{
    fecha_inicio(){
      this.getFechaInicio
    }
    
  },
  computed: {
    ...mapState("convenioMarcoAdmin", [
      "id_agreement_marco",
      "agreement",
      "fases_data",
      "start_date",
      "bad_etapas",
      "alert_etapas",
    ]),
    fechaInicioErrorMessage() {
      const errors = [];
      if (!this.$v.fecha_inicio.$dirty) return errors;
      !this.$v.fecha_inicio.required &&
        errors.push("Debe seleccionar fecha de inicio");
      !this.$v.fecha_inicio.dateValidation &&
        errors.push("La fecha de inicio debe ser menor a la fecha de fin");
      return errors;
    },
    fechaFinErrorMessage() {
      const errors = [];
      if (!this.$v.fecha_fin.$dirty) return errors;
      !this.$v.fecha_fin.required &&
        errors.push("Debe seleccionar fecha de finalización");
      return errors;
    },
    horaInicioErrorMessage() {
      const errors = [];
      if (!this.$v.hora_inicio.$dirty) return errors;
      !this.$v.hora_inicio.required &&
        errors.push("Debe escribir la hora de inicio");
      return errors;
    },
    horaFinErrorMessage() {
      const errors = [];
      if (!this.$v.hora_fin.$dirty) return errors;
      !this.$v.hora_fin.required &&
        errors.push("Debe escribir la hora de finalización");
      return errors;
    },
    fechaInicioConvenioErrorMessage() {
      const errors = [];
      if (!this.$v.fecha_inicio_convenio.$dirty) return errors;
      !this.$v.fecha_inicio_convenio.required && errors.push("Campo requerido");
      !this.$v.fecha_inicio_convenio.dateConvenioValidation &&
        errors.push("La fecha de inicio debe ser menor a la fecha de fin");
      return errors;
    },
    fechaFinConvenioErrorMessage() {
      const errors = [];
      if (!this.$v.fecha_fin_convenio.$dirty) return errors;
      !this.$v.fecha_fin_convenio.required && errors.push("Campo requerido");
      return errors;
    },
    documentoErrorMessage() {
      const errors = [];
      if (!this.$v.documento.$dirty) return errors;
      !this.$v.documento.required && errors.push("Debe cargar un documento");
      !this.$v.documento.file_type_validation &&
        errors.push("El archivo debe tener extensión .pdf");
      !this.$v.documento.fileMaxSize && LegacyValidations.pushToErrorsArray(errors);
      return errors;
    },
    observacionErrorMessage() {
      const errors = [];
      if (!this.$v.observacion.$dirty) return errors;
      !this.$v.observacion.required && errors.push("Campo requerido");
      return errors;
    },
    getFechaInicio() {
      return this.fecha_inicio
        ? moment(this.fecha_inicio).format("DD/MM/YYYY hh:mm A")
        : "";
    },
    getMinDate() {
      return this.fecha_inicio
        ? moment(this.fecha_inicio).format("YYYY-MM-DD HH:mm")
        : "";
    },
    getFechaFin() {
      return this.fecha_fin
        ? moment(this.fecha_fin).format("DD/MM/YYYY hh:mm A")
        : "";
    },
    getFechaInicioConvenio() {
      return this.fecha_inicio_convenio
        ? moment(this.fecha_inicio_convenio).format("DD/MM/YYYY")
        : "";
    },
    getFechaFinConvenio() {
      return this.fecha_fin_convenio
        ? moment(this.fecha_fin_convenio).format("DD/MM/YYYY")
        : "";
    },
  },
  methods: {
    ...mapActions("convenioMarcoAdmin", [
      "postPhaseAgreement",
      "putPhaseAgreement",
      "putAgreementMarco",
      "getDocumento",
      "getAgreementMarco",
    ]),
    ...mapMutations("convenioMarcoAdmin", [
      "setStartDate",
      "setBadEtapas",
      "setAlertEtapas",
      "setVigenciaState",
    ]),
    clean() {
      this.fecha_inicio = "";
      this.fecha_fin = "";
      this.hora_inicio = "";
      this.hora_fin = "";
      this.observacion = "";
      this.$v.fecha_inicio.$reset();
      this.$v.fecha_fin.$reset();
      this.$v.documento.$reset();
      this.$v.observacion.$reset();
    },
    habilitarEdit() {
      if (
        this.fecha_inicio_convenio !== null &&
        this.fecha_fin_convenio !== null
      ) {
        this.editModeDate = true;
      }
    },
    closeAlert(toggle) {
      this.setAlertEtapas(false);
      toggle();
    },
    checkConfiguration() {
      let count = 0;
      for (let i = 0; i < this.items.length; i++) {
        if (
          this.items[i].convenio_fase !== null &&
          this.items[i].convenio_fase !== undefined
        ) {
          count++;
        }
      }
      if (count === this.items.length) {
        this.fecha_minima_convenio = moment(
          this.items[this.items.length - 1].convenio_fase.fecha_fin
        ).format("YYYY-MM-DD HH:mm");
        this.showDatePicker = true;
      } else return false;
    },
    statusIcon(item) {
      if (item.id === 1) return false;
      const i = this.items.indexOf(item);
      if (
        item.convenio_fase === undefined &&
        this.items[i - 1].convenio_fase === undefined
      )
        return true;
    },
    async configurarFase(item) {
      this.clean();
      this.id_phase = item.id;
      this.phase_name = item.nombre;
      if (this.id_phase === 1) {
        this.document_path = item.ruta;
        this.document_name = item.nombre_documento;
        if (this.document_path) {
          await this.documentoBases();
        }
      } else {
        this.documento = null;
      }
      this.fecha_minima = "";
      this.fecha_maxima = "";
      this.obtenerFechaMinima(item);
      if (item.convenio_fase) {
        this.fecha_inicio = "";
        this.fecha_fin = "";
        this.id = item.convenio_fase.id;
        this.fecha_inicio = moment(item.convenio_fase.fecha_inicio).format(
          "YYYY-MM-DD HH:mm"
        );
        this.fecha_fin = moment(item.convenio_fase.fecha_fin).format(
          "YYYY-MM-DD HH:mm"
        );
        if (this.id_phase !== 1) this.obtenerFechaMaxima(item);
        this.observacion = item.convenio_fase.observacion;
        this.editMode = true;
      } else {
        this.editMode = false;
        if (this.id_phase !== 1) {
          let currentIndex = this.items.indexOf(item);
          let fechaEtapaAnterior =
            this.items[currentIndex - 1].convenio_fase.fecha_fin;
          this.fecha_inicio = moment(fechaEtapaAnterior).add(1, "minutes");
        }
      }
      this.showDialog = true;
    },
    horasDisponibles(n) {
      switch (n) {
        case 1:
          this.horas_disponibles_inicio = [];
          if (
            this.fecha_minima !==
            moment(this.fecha_inicio).format("YYYY-MM-DD HH:mm")
          ) {
            for (let i = 0; i <= 23; i++) {
              this.horas_disponibles_inicio.push(Number(i));
            }
          } else {
            const hora = this.fecha_minima.split(" ")[1].split(":")[0];
            for (let i = Number(hora); i <= 23; i++) {
              this.horas_disponibles_inicio.push(Number(i));
            }
            this.horas_disponibles_inicio.push(Number(0));
          }
          break;
        case 2:
          this.horas_disponibles_fin = [];
          if (
            moment(this.fecha_inicio).format("YYYY-MM-DD") !==
            moment(this.fecha_fin).format("YYYY-MM-DD")
          ) {
            for (let i = 0; i <= 23; i++) {
              this.horas_disponibles_fin.push(Number(i));
            }
          } else {
            const hora = moment(this.fecha_inicio)
              .format("YYYY-MM-DD HH:mm")
              .split(" ")[1]
              .split(":")[0];
            const min = moment(this.fecha_inicio)
              .format("YYYY-MM-DD HH:mm")
              .split(" ")[1]
              .split(":")[1];
            if (min > 30) {
              for (let i = Number(hora); i <= 23; i++) {
                this.horas_disponibles_fin.push(Number(i));
              }
            } else {
              for (let i = Number(hora); i <= 23; i++) {
                this.horas_disponibles_fin.push(Number(i));
              }
            }
            this.horas_disponibles_fin.push(Number(0));
          }
          break;
        default:
          break;
      }
    },
    obtenerFechaMinima(item) {
      const i = this.items.indexOf(item);
      if (i !== 0) {
        if (this.items[i].convenio_fase) {
          const date = new Date(this.items[i].convenio_fase.fecha_fin);
          date.setDate(date.getDate());
          this.fecha_minima = moment(date.toISOString()).format(
            "YYYY-MM-DD HH:mm"
          );
          const hora = this.fecha_minima.split(" ")[1].split(":")[0];
          this.horas_disponibles_inicio = [];
          if (
            this.fecha_minima !==
            moment(this.fecha_inicio).format("YYYY-MM-DD HH:mm")
          ) {
            for (let i = 0; i <= 23; i++) {
              this.horas_disponibles_inicio.push(Number(i));
            }
          } else {
            for (let i = Number(hora); i <= 23; i++) {
              this.horas_disponibles_inicio.push(Number(i));
            }
            this.horas_disponibles_inicio.push(Number(0));
          }
        }
      }
    },
    obtenerFechaMaxima(item) {
      const i = this.items.indexOf(item);
      if (i !== 4) {
        if (this.items[i + 1].convenio_fase) {
          const date = new Date(this.items[i].convenio_fase.fecha_inicio);
          date.setDate(date.getDate());
          this.fecha_maxima = moment(date.toISOString()).format(
            "YYYY-MM-DD HH:mm"
          );
          this.horas_disponibles_fin = [];
          if (
            moment(this.fecha_inicio).format("YYYY-MM-DD HH:mm") !==
            moment(this.fecha_fin).format("YYYY-MM-DD HH:mm")
          ) {
            for (let i = 0; i <= 23; i++) {
              this.horas_disponibles_fin.push(Number(i));
            }
          } else {
            const hora = moment(this.fecha_inicio)
              .format("YYYY-MM-DD HH:mm")
              .split(" ")[1]
              .split(":")[0];
            const min = moment(this.fecha_inicio)
              .format("YYYY-MM-DD HH:mm")
              .split(" ")[1]
              .split(":")[1];
            if (min > 30) {
              for (let i = Number(hora) + 1; i <= 23; i++) {
                this.horas_disponibles_fin.push(Number(i));
              }
            } else {
              for (let i = Number(hora); i <= 23; i++) {
                this.horas_disponibles_fin.push(Number(i));
              }
            }
            this.horas_disponibles_fin.push(Number(0));
          }
        }
      }
    },
    async documentoBases() {
      const response = await this.getDocumento(this.document_path);
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      } else {
        const blob = new Blob([response], {
          type: "application/pdf",
        });
        this.documento = new File([blob], `${this.document_name}`, {
          type: "application/pdf",
        });
      }
    },
    async obtenerDocumentoBases() {
      const response = await this.getDocumento(this.document_path);
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      } else {
        const fileUrl = window.URL.createObjectURL(new Blob([response]));
        const fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute("download", `${this.document_name}`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
    getCurrentDay() {
      const date = new Date(Date.now());
      this.fecha_actual = moment(date).format("YYYY-MM-DD HH:mm");
    },
    async guardarConfirguracion() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        const data = new FormData();
        data.append(
          "fecha_inicio",
          moment(this.fecha_inicio).add(0, "s").format("YYYY-MM-DD HH:mm:ss")
        );
        data.append(
          "fecha_fin",
          moment(this.fecha_fin).add(0, "s").format("YYYY-MM-DD HH:mm:ss")
        );
        data.append("observacion", this.observacion);
        if (this.documento !== null) {
          data.append("documento", this.documento);
        }
        const params = {
          id_agreement: this.id_agreement_marco,
          id_phase: this.id_phase,
          data: data,
        };
        const response = await this.postPhaseAgreement(params);
        this.setBadEtapas(false);
        if (response.status === 201) {
          this.temporalAlert({
            show: true,
            message: "Fase configurada exitosamente",
            type: "success",
          });
          if (this.id_phase === 5) {
            this.showDatePicker = true;
            this.fecha_minima_convenio = this.fecha_fin;
          }
          this.clean();
          this.$v.$reset();
          this.showDialog = false;
          this.$emit("reload");
        }
      }
      this.loading = false;
    },
    async actualizarConfiguracion() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.loading = true;
        const data = new FormData();

        data.append(
          "fecha_inicio",
          moment(this.fecha_inicio).add(0, "s").format("YYYY-MM-DD HH:mm:ss")
        );

        data.append(
          "fecha_fin",
          moment(this.fecha_fin).add(0, "s").format("YYYY-MM-DD HH:mm:ss")
        );

        data.append("observacion", this.observacion);
        if (this.isDocUpdate) {
          if (this.documento !== null) {
            data.append("documento", this.documento);
          }
        }
        const params = {
          id_convenio_fase: this.id,
          data: data,
        };
        const response = await this.putPhaseAgreement(params);
        if (response.status === 200 || response.status === 201) {
          this.temporalAlert({
            show: true,
            message: response.data.message,
            type: "success",
          });
          if (this.id_phase === 5) {
            this.fecha_minima_convenio = moment(
              this.items[this.items.length - 1].convenio_fase.fecha_fin
            ).format("YYYY-MM-DD HH:mm");
          }
          this.showDialog = false;
          this.$emit("reload");
        }
      }
      this.loading = false;
    },
    async guardarFechas() {
      this.$v.$touch();
      if (
        !this.$v.$params.fecha_inicio_convenio &&
        !this.$v.$params.fecha_fin_convenio
      ) {
        const data = new FormData();
        data.append(
          "fecha_inicio",
          moment(this.fecha_inicio_convenio).format("YYYY-MM-DD hh:mm:ss")
        );
        data.append(
          "fecha_fin",
          moment(this.fecha_fin_convenio).format("YYYY-MM-DD hh:mm:ss")
        );
        const params = {
          id: this.id_agreement_marco,
          agreement: data,
        };
        const response = await this.putAgreementMarco(params);
        if (response.status === 201) {
          this.temporalAlert({
            show: true,
            message: "Fechas guardadas con éxito",
            type: "success",
          });
          this.setStartDate(response.data.fecha_inicio);
          this.setVigenciaState(false);
        } else {
          this.temporalAlert({
            show: true,
            message: response.data.message,
            type: "error",
          });
        }
      }
    },
  },
  async created() {
    this.getCurrentDay();
    this.checkConfiguration();
    if (this.id_agreement_marco !== null) {
      await this.getAgreementMarco(this.id_agreement_marco);
      this.fecha_inicio_convenio = this.agreement.fecha_inicio;
      this.fecha_fin_convenio = this.agreement.fecha_fin;
      this.horasDisponibles(1);
      this.horasDisponibles(2);
    }
  },
};
</script>

<style>
.cursor {
  cursor: pointer;
}
.custom-height {
  height: 120px !important;
}
.v-messages[role="alert"] .v-messages__message {
  color: red !important;
}
</style>
