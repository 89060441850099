<template>
  <v-row class="d-flex justify-space-between mt-15">
    <div></div>
    <div>
      <v-btn class="mx-5" text color="secondary" @click="step === 1 ? cancelar() : prevTab()">
        {{ (step === 1) ? 'Volver al listado' : 'Atrás'}}
      </v-btn>
      <v-btn v-if="step !== 4 && id_agreement_marco !== null" class="mx-5" outlined color="secondary" @click="nextTab()">Siguiente</v-btn>
      <v-btn v-if="step === 1 && id_agreement_marco === null" class="mx-5" color="secondary" @click="actionButton()">Guardar</v-btn>
    </div>
    <div>
      <v-btn v-if="step === 1 && id_agreement_marco === null"  outlined color="red" @click="showDialog()">Cancelar</v-btn>
    </div>
    <confirmation-dialog 
      @confirm="cancelar()" 
      @close="dialog = false" 
      :show="dialog" 
      :title="dialogTitle" 
      :message="dialogMessage"
    />
  </v-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import ConfirmationDialog from '../../../components/ConfirmationDialogComponent.vue'

export default {
  name: 'ActionButtonComponent',
  data: () => ({
    dialog: false,
    dialogTitle: '',
    dialogMessage: '',
  }),
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  components: {
    ConfirmationDialog
  },
  computed: {
    ...mapState('convenioMarcoAdmin', [
        'id_agreement_marco',
        'general_data',
        'tab',
        'lista_obs_convenio',
        'lista_document_convenio',
      ]
    ),
  },
  methods: {
    ...mapActions('convenioMarcoAdmin', [
      'loadGeneralData',
      'saveIdAgreementMarco',
      'clearGeneralData',
      'nextTab',
      'prevTab',
    ]),
    ...mapMutations('convenioMarcoAdmin', [
      'setObsAgreementList',
      'setDocumentAgreementList',
      'setPhasesAgreementList',
    ]),
    showDialog(){
      let action
      (this.step === 1) ? action = 'creación ' : action = 'configuración '
      this.dialogTitle = `¿Deseas cancelar la ${action} del convenio marco?`
      this.dialogMessage = 'De confirmarse la siguiente acción, los cambios no serán reversibles..'
      this.dialog = true
    },
    actionButton(){
      if(this.step === 1) this.$emit('general')
      if(this.step === 2) this.$emit('obs')
    },
    cancelar(){
      this.saveIdAgreementMarco(null)
      this.clearGeneralData([])
      this.setObsAgreementList([])
      this.setDocumentAgreementList([])
      this.setPhasesAgreementList([])
      this.$router.push('/convenio-marco-list')
    },
  },
}
</script>

<style scoped>
  .v-btn{
    text-transform: none;
  }
</style>